<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <!--    <profile-header :header-data="profileData.header" />-->
    <b-alert
      variant="danger"
      :show="(member_type !== 2 && member_type !== 3 && !profileData.kin.length) || !profileData.payment_information.length"
    >
      <div class="alert-body">
        Please complete your profile for review. <br>
        <b>Account will remain pending until profile is complete.</b>
      </div>
    </b-alert>
    <b-row>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card v-if="profileData.status === 'Approved'">
          <b-card-title title-tag="h2">
            Membership Number
          </b-card-title>
          <b-card-body>
            {{ profileData.registration_number }}
          </b-card-body>
        </b-card>
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Change Profile Photo
          </b-card-title>
          <b-avatar
            :src="profileData.avatar"
            size="330px"
            rounded
          />

          <validation-observer
            ref="updateAvatarForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="updateAvatar"
            >
              <div>
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <b-form-group
                    label="Update Image"
                    label-for="change-avatar"
                  >
                    <b-form-file
                      id="avatar"
                      v-model="avatar"
                      :state="errors.length > 0 ? false : null"
                      name="avatar"
                      accept=".jpg,.jpeg,.png"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-show="show_avatar_too_big_error"
                    class="text-danger"
                  >
                    File too large. Max size 5MB.
                  </small>
                </validation-provider>
                <!-- submit button -->
                <b-button
                  variant="primary"
                  block
                  size="sm"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Update Image" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="8"
      >
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Member Details (Cannot be edited)
          </b-card-title>
          <validation-observer
            ref="updateDetailsForm"
          >
            <b-form
              class="auth-register-form mt-2"
            >
              <b-row>
                <!-- Select Type -->
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  class="d-flex"
                >
                  <!-- <b-form-group
                    label="Member Type"
                    label-for="type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="type"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-select
                          v-model="member_type"
                          readonly
                          :options="member_types"
                          size="md"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->
                  <h4>Member Type:</h4>
                  <span class="fw-bold ml-1">{{ member_type_name }}</span>
                </b-col>
                <!-- Surname -->
                <b-col
                  v-if="member_type !== 2 && member_type !== 3"
                  lg="6"
                  sm="12"
                >
                  <!-- firstname -->
                  <b-form-group
                    v-if="member_type !== 2 && member_type !== 3"
                    :label="member_type === 3 ? 'Guardian\'s Surname' : 'Surname'"
                    label-for="surname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Surname"
                      rules="required"
                    >
                      <b-form-input
                        id="surname"
                        v-model="surname"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="register-surname"
                        placeholder="Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Other Names -->
                <b-col
                  v-if="member_type !== 2 && member_type !== 3"
                  lg="6"
                  sm="12"
                >
                  <!-- lastname -->
                  <b-form-group
                    v-if="member_type !== 2 && member_type !== 3"
                    :label="member_type === 3 ? 'Guardian\'s Other Names' : 'Other Names'"
                    label-for="other_names"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Other Names"
                      rules="required"
                    >
                      <b-form-input
                        id="other_names"
                        v-model="other_names"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="Other Names"
                        placeholder="John"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Business Name -->
                <b-col
                  v-if="member_type === 2 || member_type === 3"
                  lg="6"
                  sm="12"
                >
                  <!-- Business Name -->
                  <b-form-group
                    v-if="member_type === 2 || member_type === 3"
                    label="Business Name"
                    label-for="business-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Name"
                      rules="required"
                    >
                      <b-form-input
                        id="business-name"
                        v-model="business_name"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="business-name"
                        placeholder="Enter Business Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- ID Number -->
                <b-col
                  v-if="member_type !== 2 && member_type !== 3"
                  lg="6"
                  sm="12"
                >
                  <!-- id_number -->
                  <b-form-group
                    v-if="member_type !== 2 && member_type !== 3"
                    :label="member_type === 3 ? 'Guardian\'s ID No or Passport No.' : 'ID Number or Passport Number'"
                    label-for="id_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="id_number"
                      rules="required"
                      vid="id_number"
                    >
                      <b-form-input
                        id="id_number"
                        v-model="id_number"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="id-number"
                        placeholder="*********"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Business Registration Number -->
                <b-col
                  v-if="member_type === 2 || member_type === 3"
                  lg="6"
                  sm="12"
                >
                  <!-- business_registration_number -->
                  <b-form-group
                    v-if="member_type === 2 || member_type === 3"
                    label="Business Registration Number"
                    label-for="business_registration_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Registration Number"
                      rules="required"
                      vid="business_registration_number"
                    >
                      <b-form-input
                        id="business_registration_number"
                        v-model="business_registration_number"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="business-registration-number"
                        placeholder="Enter Business Registration Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- KRA Pin -->
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- kra_pin -->
                  <b-form-group
                    v-if="member_type === 1 || member_type === 5 || member_type === ''"
                    label="KRA PIN"
                    label-for="kra_pin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="KRA PIN"
                      rules="required"
                      vid="kra_pin"
                    >
                      <b-form-input
                        id="kra_pin"
                        v-model="kra_pin"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="kra-pin"
                        placeholder="*********"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type === 2 || member_type === 3"
                    label="Business's KRA PIN"
                    label-for="kra_pin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="KRA PIN"
                      rules="required"
                      vid="kra_pin"
                    >
                      <b-form-input
                        id="business_kra_pin"
                        v-model="kra_pin"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="KRA PIN"
                        placeholder="*********"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type === 4"
                    label="Guardian's KRA PIN"
                    label-for="kra_pin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="KRA PIN"
                      rules="required"
                      vid="kra_pin"
                    >
                      <b-form-input
                        id="guardian_kra_pin"
                        v-model="kra_pin"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="kra-pin"
                        placeholder="*********"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Deceased Person Name -->
                <b-col
                  v-if="member_type === 5"
                  lg="12"
                  sm="12"
                >
                  <!-- Deceased Person Name -->
                  <b-form-group
                    v-if="member_type === 5"
                    label="Deceased Person's Name"
                    label-for="deceased_person_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Deceased Person Name"
                      rules="required"
                    >
                      <b-form-input
                        id="deceased_person_name"
                        v-model="deceased_person_name"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="Deceeased Person Name"
                        placeholder="John Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Gender -->
                <b-col
                  v-if="member_type !== 2 && member_type !== 3"
                  lg="6"
                  sm="12"
                  class="mb-1"
                >
                  <b-row>
                    <b-col
                      lg="5"
                      sm="12"
                    >
                      <p class="font-weight-bolder">
                        Gender:
                      </p>
                    </b-col>
                    <b-col
                      lg="7"
                      sm="12"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Gender"
                          rules="required"
                          vid="gender"
                        >
                          <div class="d-flex">
                            <b-form-radio
                              v-for="gender in gender_options"
                              :key="gender"
                              v-model="member_gender"
                              class="mx-2"
                              plain
                              :value="gender"
                            >
                              {{ gender }}
                            </b-form-radio>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Stage Name -->
                <b-col
                  v-if="stage_name || (member_type === 1 || member_type === 4)"
                  lg="6"
                  sm="12"
                >
                  <!-- Stage Name -->
                  <b-form-group
                    label="Stage Name"
                    label-for="stage_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Stage Name"
                      rules="required"
                    >
                      <b-form-input
                        id="stage_name"
                        v-model="stage_name"
                        :state="errors.length > 0 ? false : null"
                        name="Stage Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Email -->
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- email -->
                  <b-form-group
                    v-if="member_type === 1 || member_type === 4 || member_type === 5 || member_type === ''"
                    :label="member_type === 4 ? 'Guardian\'s Email' : 'Email'"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="personal_email"
                        v-model="regEmail"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="register-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type === 2 || member_type === 3"
                    label="Business Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="business_email"
                        v-model="regEmail"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="register-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Phone Number -->
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Phone Number -->
                  <b-form-group
                    v-if="member_type === 1 || member_type === 4 || member_type === 5 || member_type === ''"
                    :label="member_type === 4 ? 'Guardian\'s Phone Number' : 'Phone Number'"
                    label-for="phone_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                      vid="phone_number"
                    >
                      <b-form-input
                        id="personal_phone_number"
                        v-model="phone_number"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="phone-number"
                        placeholder="07000000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type === 2 || member_type === 3"
                    label="Business Phone Number"
                    label-for="phone_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                      vid="phone_number"
                    >
                      <b-form-input
                        id="phone_number"
                        v-model="phone_number"
                        readonly
                        :state="errors.length > 0 ? false : null"
                        name="phone-number"
                        placeholder="07000000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Minor's Name -->
                <b-col
                  v-if="member_type === 4"
                  lg="6"
                  sm="12"
                >
                  <!-- Minor Names -->
                  <b-form-group
                    v-if="member_type === 4"
                    label="Minor's Name"
                    label-for="child_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Minor's Name"
                      rules="required"
                      vid="child_name"
                    >
                      <b-form-input
                        id="child_name"
                        v-model="child_name"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Child Guardian Relationship -->
                <b-col
                  v-if="member_type === 4"
                  lg="6"
                  sm="12"
                >
                  <!-- Child Guardian Relationship -->
                  <b-form-group
                    v-if="member_type === 4"
                    label="Relationship"
                    label-for="child_relationship"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Relationship"
                      rules="required"
                      vid="child_relationship"
                    >
                      <b-form-input
                        id="child_relationship"
                        v-model="child_relationship"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Child Age -->
                <b-col
                  v-if="member_type === 3"
                  lg="6"
                  sm="12"
                >
                  <!-- Age -->
                  <b-form-group
                    v-if="member_type === 3"
                    label="Age"
                    label-for="age"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Age"
                      rules="required"
                      vid="age"
                    >
                      <b-form-spinbutton
                        id="age"
                        v-model="age"
                        readonly
                        min="1"
                        max="18"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit button -->
              <!-- <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || isLoading"
              >
                {{ !isLoading ? "Update Details" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button> -->
            </b-form>
          </validation-observer>
        </b-card>
        <!-- Password Card -->
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Update Password
          </b-card-title>
          <validation-observer
            ref="updatePasswordForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="updatePassword"
            >
              <b-row>
                <b-col
                  lg="4"
                  sm="12"
                >
                  <b-form-group
                    label="Enter Old Password"
                    label-for="old-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Old Password"
                      rules="required"
                      vid="old_password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="old_password"
                          v-model="old_password"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="Old Password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="4"
                  sm="12"
                >
                  <b-form-group
                    label="Enter New Password"
                    label-for="new-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      rules="required"
                      vid="new_password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="new_password"
                          v-model="new_password"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="New Password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="4"
                  sm="12"
                >
                  <b-form-group
                    label="Confirm New Password"
                    label-for="confirm-new-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password Confirmation"
                      rules="required"
                      vid="confirm_new_password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password_confirmation"
                          v-model="confirm_new_password"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="Password Confirmation"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                size="sm"
                type="submit"
                :disabled="invalid || isLoading"
              >
                {{ !isLoading ? "Update Password" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- Next of Kin -->
      <b-col
        v-if="member_type !== 2 && member_type !== 3"
        lg="6"
        sm="12"
      >
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Update Next Of Kin
          </b-card-title>
          <validation-observer
            ref="updateNextOfKinForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="updateNextOfKin"
            >
              <b-row>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Next Of Kin's Name"
                    rules="required"
                    vid="next_of_kin_name"
                  >
                    <b-form-group
                      label="Names"
                      label-for="nex-of-kin-name"
                    >
                      <b-form-input
                        id="next_of_kin_name"
                        v-model="next_of_kin_name"
                        :state="errors.length > 0 ? false : null"
                        name="Next Kin's Name"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <b-form-group
                    label="Phone Number"
                    label-for="next-of-kin-phone-number"
                  >
                    <b-form-input
                      id="next_of_kin_phone_number"
                      v-model="next_of_kin_phone_number"
                      name="Next Of Kin Phone Number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Next Of Kin Relationship"
                    rules="required"
                    vid="relationship"
                  >
                    <b-form-group
                      label="Relationship"
                      label-for="next-of-kin-relationship"
                    >
                      <b-form-input
                        id="next_of_kin_relationship"
                        v-model="next_of_kin_relationship"
                        :state="errors.length > 0 ? false : null"
                        name="Relationship"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="!profileData.kin.length"
                  lg="12"
                  sm="12"
                >
                  <b-form-group
                    label-for="identification document"
                    label="National ID, Passport or Birth Certificate"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Next of Kin ID Document"
                      vid="id_document"
                    >
                      <b-form-file
                        id="next-of-kin-identification-document"
                        v-model="identification_document"
                        name="next-of-kin-identification-document"
                        accept=".pdf"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                size="sm"
                type="submit"
                :disabled="invalid || isLoading"
              >
                {{ !isLoading ? "Update Next Of Kin" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <!-- Payment Information -->
      <b-col
        lg="6"
        sm="12"
      >
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Update Payment Information
          </b-card-title>
          <validation-observer
            ref="updatePaymentInformationForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="updatePaymentInformation"
            >
              <b-row>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <b-form-group
                    label-for="bank_name"
                    label="Bank Name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Bank Name"
                      vid="bank_name"
                    >
                      <b-form-input
                        id="bank-name"
                        v-model="bank_name"
                        name="bank-name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Bank Account Name -->
                  <b-form-group
                    label-for="bank_account_name"
                    label="Enter Bank Account Name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Bank Account Name"
                      vid="bank_account_name"
                    >
                      <b-form-input
                        id="bank-account-name"
                        v-model="bank_account_name"
                        name="bank-account-name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Bank Account Number -->
                  <b-form-group
                    label-for="bank_account_number"
                    label="Enter Bank Account Number"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Bank Account number"
                      vid="bank_account_number"
                    >
                      <b-form-input
                        id="bank-account-number"
                        v-model="bank_account_number"
                        name="bank-account-number"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Bank Branch -->
                  <b-form-group
                    label-for="bank_branch"
                    label="Enter Bank Branch"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Bank Branch"
                      vid="bank_branch"
                    >
                      <b-form-input
                        id="bank-branch"
                        v-model="bank_branch"
                        name="bank-branch"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Mobile Money Phone Number -->
                  <b-form-group
                    label-for="mobile_money_phone_number"
                    label="Enter Mobile Money Registered Phone Number"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile Money Phone Number"
                      vid="mobile_money_phone_number"
                    >
                      <b-form-input
                        id="mobile-money-phone-number"
                        v-model="mobile_money_phone_number"
                        name="mobile-money-phone-number"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Mobile Money Provider -->
                  <b-form-group
                    label-for="mobile_money_provider"
                    label="Enter Mobile Money Service Provider"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile Money Provider"
                      vid="mobile_money_provider"
                    >
                      <b-form-input
                        id="mobile-money-provider"
                        v-model="mobile_money_provider"
                        name="mobile-money-provider"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Mobile Money Registered Name -->
                  <b-form-group
                    label-for="mobile_money_registered_name"
                    label="Enter Mobile Money Registered Name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile Money Registered Name"
                      vid="mobile_money_registered_name"
                    >
                      <b-form-input
                        id="mobile-money-registered-name"
                        v-model="mobile_money_registered_name"
                        name="mobile-money-registered-name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                size="sm"
                type="submit"
                :disabled="invalid || isLoading"
              >
                {{ !isLoading ? "Update Payment Information" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <!-- Member Partner Details -->
      <b-col
        v-if="member_type === 2 || member_type === 3"
        lg="6"
        sm="12"
      >
        <b-card>
          <b-col
            v-if="member_type === 2 || member_type === 3"
            cols="12"
          >
            <b-row>
              <b-col cols="6">
                <h5>Director Details</h5>
              </b-col>
              <b-col cols="6">
                <b-button
                  size="sm"
                  class="float-right"
                  @click="addDirector"
                >
                  Add Director
                </b-button>
              </b-col>
            </b-row>
            <validation-observer
              ref="updatePartnersInformationForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="updatePartnersInformation"
              >
                <b-row
                  v-for="counter in partners_counter"
                  :key="counter"
                >
                  <b-col cols="6">
                    <h6>{{ counter }}{{ nth(counter) }} Director</h6>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      v-if="partners_counter > 1"
                      size="sm"
                      variant="danger"
                      class="float-right"
                      @click="removeDirector(counter)"
                    >Delete {{ counter }}{{ nth(counter) }} Director</b-button>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Surname"
                      :label-for="counter+'-first-name'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="counter+''+nth(counter)+' Director\'s Surname'"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter+'-director-first-name'"
                          v-model="partners_first_name[counter]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter+''+nth(counter)+' Director\'s Surname'"
                          placeholder="Enter Director First Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Other Names"
                      :label-for="counter+'-last-name'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="counter+''+nth(counter)+' Director\'s Other Names'"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter+'-director-last-name'"
                          v-model="partners_last_name[counter]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter+''+nth(counter)+' Director\'s Other Names'"
                          placeholder="Enter Director Last Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group
                      label="ID Number"
                      :label-for="counter+'-id-number'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="counter+''+nth(counter)+' Director\'s ID Number'"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter+'-director-id-number'"
                          v-model="partners_id_number[counter]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter+''+nth(counter)+' Director\'s ID Number'"
                          placeholder="Enter Director ID Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Phone Number"
                      :label-for="counter+'-phone-number'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="counter+''+nth(counter)+' Director\'s Phone Number'"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter+'-director-phone-number'"
                          v-model="partners_phone_number[counter]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter+''+nth(counter)+' Director\'s Phone Number'"
                          placeholder="Enter Director Phone Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Email"
                      :label-for="counter+'-email'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="counter+''+nth(counter)+' Director\'s Email'"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter+'-director-email'"
                          v-model="partners_email[counter]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter+''+nth(counter)+' Director\'s Email'"
                          placeholder="Enter Director Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group
                      label="Shares Held"
                      :label-for="counter+'-shares'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="counter+''+nth(counter)+'Director\'s Shares'"
                        rules="required"
                      >
                        <b-form-input
                          :id="counter+'-director-shares'"
                          v-model="partners_shares[counter]"
                          :state="errors.length > 0 ? false : null"
                          :name="counter+''+nth(counter)+' Director\'s shares'"
                          placeholder="Enter Director's Shares"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- submit button -->
                <b-button
                  variant="primary"
                  block
                  size="sm"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Update Partners Information" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <b-card v-if="profileData.roles">
      <b-card-body>
        <span class="font-bold">
          Member Role:
        </span>
        <span class="m_title">
          {{ profileData.roles.replaceAll('"', '') }}
        </span>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BAvatar,
  BSpinner,
  BCard,
  BFormGroup,
  BForm,
  BFormFile,
  BButton,
  BFormInput,
  BCardTitle,
  BInputGroup,
  BInputGroupAppend,
  BFormSpinbutton,
  // BFormSelect,
  BFormRadio,
  BCardBody,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BCard,
    BFormGroup,
    BForm,
    BFormFile,
    BButton,
    BFormInput,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    BFormSpinbutton,
    BAlert,
    // BFormSelect,
    BFormRadio,
    BCardBody,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      profileData: { },
      gender_options: [
        'Male',
        'Female',
      ],
      isLoading: false,
      avatar: null,
      show_avatar_too_big_error: false,
      member_types: [],
      member_type: '',
      member_type_name: '',
      id_number: '',
      surname: '',
      other_names: '',
      business_name: '',
      member_gender: '',
      business_registration_number: '',
      regEmail: '',
      phone_number: '',
      kra_pin: '',
      password: '',
      child_name: '',
      age: '',
      child_relationship: '',
      deceased_person_name: '',
      deceased_death_certificate: null,
      deceased_letter_of_administration: null,
      stage_name: '',
      // Password
      old_password: '',
      new_password: '',
      confirm_new_password: '',
      // Next of Kin
      next_of_kin_name: '',
      next_of_kin_phone_number: '',
      next_of_kin_relationship: '',
      identification_document: null,
      // Payment information
      bank_account_name: '',
      bank_account_number: '',
      bank_branch: '',
      bank_name: '',
      mobile_money_provider: '',
      mobile_money_registered_name: '',
      mobile_money_phone_number: '',
      // Partners
      partners_counter: 0,
      partners: [],
      partners_id: [],
      partners_first_name: [],
      partners_last_name: [],
      partners_id_number: [],
      partners_phone_number: [],
      partners_email: [],
      partners_shares: [],

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    regEmail(newEmail) {
      if (this.member_type !== 3) {
        this.$http.post('/membership/auth/email/check', { email: newEmail })
          .then(() => {})
          .catch(error => {
            this.$refs.updateDetailsForm.setErrors(error.response.data.data)
          })
      }
    },
    phone_number(newPhoneNumber) {
      if (this.member_type !== 4) {
        this.$http.post('/membership/auth/phone/check', { phone_number: newPhoneNumber })
          .then(() => {})
          .catch(error => {
            this.$refs.updateDetailsForm.setErrors(error.response.data.data)
          })
      }
    },
    kra_pin(newKraPin) {
      if (this.member_type !== 4) {
        this.$http.post('/membership/auth/kra-pin/check', { kra_pin: newKraPin })
          .then(() => {})
          .catch(error => {
            this.$refs.updateDetailsForm.setErrors(error.response.data.data)
          })
      }
    },
    id_number(newIdNumber) {
      if (this.member_type === 1) {
        this.$http.post('/membership/auth/id-number/check', { id_number: newIdNumber })
          .then(() => {})
          .catch(error => {
            this.$refs.updateDetailsForm.setErrors(error.response.data.data)
          })
      }
    },
    business_registration_number(newBusinessRegistrationNumber) {
      if (this.member_type === 2 || this.member_type === 3) {
        this.$http.post('/membership/auth/business-registration-number/check', { business_registration_number: newBusinessRegistrationNumber })
          .then(() => {})
          .catch(error => {
            this.$refs.updateDetailsForm.setErrors(error.response.data.data)
          })
      }
    },
    avatar(newAvatar) {
      if (newAvatar.size > 5000000) {
        this.show_avatar_too_big_error = true
      } else {
        this.show_avatar_too_big_error = false
      }
    },
  },
  created() {
    this.$http.get('/membership/member/types')
      .then(response => {
        response.data.data.forEach(type => {
          this.member_types.push({ text: type.name, value: type.id })
        })
      })
      .catch(error => {
        console.log(error)
      })
    this.$http.get('/membership/member')
      .then(res => {
        this.profileData = res.data.data
        this.member_type = this.profileData.member_type_id
        this.business_name = this.profileData.business_name
        this.business_registration_number = this.profileData.business_registration_number
        this.kra_pin = this.profileData.kra_pin
        this.regEmail = this.profileData.email
        this.surname = this.profileData.surname
        this.other_names = this.profileData.other_names
        this.id_number = this.profileData.id_number
        this.phone_number = this.profileData.phone_number
        this.stage_name = this.profileData.stage_name
        this.member_gender = this.profileData.gender
        if (this.member_type === 3) {
          this.age = this.profileData.age
          this.child_name = this.profileData.child_name
          this.child_relationship = this.profileData.child_guardian_relationship
        }
        if (this.member_type !== 2) {
          this.next_of_kin_name = this.profileData.kin.length ? this.profileData.kin[0].name : ''
          this.next_of_kin_last_name = this.profileData.kin.length ? this.profileData.kin[0].last_name : ''
          this.next_of_kin_phone_number = this.profileData.kin.length ? this.profileData.kin[0].phone_number : ''
          this.next_of_kin_relationship = this.profileData.kin.length ? this.profileData.kin[0].relationship : ''
        }
        if (this.member_type === 4) {
          this.deceased_person_name = this.profileData.deceased_person_name
        }
        this.bank_name = this.profileData.payment_information.length ? this.profileData.payment_information[0].bank_name : ''
        this.bank_branch = this.profileData.payment_information.length ? this.profileData.payment_information[0].bank_branch : ''
        this.bank_account_name = this.profileData.payment_information.length ? this.profileData.payment_information[0].bank_account_name : ''
        this.bank_account_number = this.profileData.payment_information.length ? this.profileData.payment_information[0].bank_account_number : ''
        this.mobile_money_phone_number = this.profileData.payment_information.length ? this.profileData.payment_information[0].mobile_money_phone_number : ''
        this.mobile_money_provider = this.profileData.payment_information.length ? this.profileData.payment_information[0].mobile_money_provider : ''
        this.mobile_money_registered_name = this.profileData.payment_information.length ? this.profileData.payment_information[0].mobile_money_registered_name : ''
        if (this.member_type === 2 || this.member_type === 3) {
          this.profileData.partners.forEach(partner => {
            this.partners_counter += 1
            this.partners_first_name[this.partners_counter] = partner.first_name
            this.partners_last_name[this.partners_counter] = partner.last_name
            this.partners_email[this.partners_counter] = partner.email
            this.partners_id_number[this.partners_counter] = partner.id_number
            this.partners_phone_number[this.partners_counter] = partner.phone_number
            this.partners_shares[this.partners_counter] = partner.shares
          })
        }
        this.member_types.forEach(type => {
          if (this.member_type === type.value) {
            this.member_type_name = type.text
          }
        })
      })
  },
  methods: {
    addDirector() {
      this.partners_counter += 1
    },
    removeDirector(counter) {
      if (this.partners_counter <= 1) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'At Lease One Partner/Director Required',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.partners_counter -= 1
      this.partners_first_name.splice(counter, 1)
      this.partners_last_name.splice(counter, 1)
      this.partners_email.splice(counter, 1)
      this.partners_phone_number.splice(counter, 1)
      this.partners_id_number.splice(counter, 1)
      this.partners_shares.splice(counter, 1)
    },
    updateAvatar() {
      if (this.avatar.size > 5000000) {
        this.show_avatar_too_big_error = true
        return
      }
      this.isLoading = true
      this.$refs.updateAvatarForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('avatar', this.avatar)
          this.$http.post('membership/update-avatar', formData)
            .then(response => {
              this.avatar = null
              this.profileData = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updateDetails() {
      this.isLoading = true
      this.$refs.updateDetailsForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('member_type', this.member_type)
          formData.append('id_number', this.id_number)
          formData.append('surname', this.surname)
          formData.append('other_names', this.other_names)
          formData.append('business_name', this.business_name)
          formData.append('business_registration_number', this.business_registration_number)
          formData.append('email', this.regEmail)
          formData.append('phone_number', this.phone_number)
          formData.append('kra_pin', this.kra_pin)
          formData.append('age', this.age)
          formData.append('child_name', this.child_name)
          formData.append('child_relationship', this.child_relationship)
          formData.append('deceased_person_name', this.deceased_person_name)
          formData.append('stage_name', this.stage_name)
          formData.append('gender', this.member_gender)
          this.$http.post('membership/update-details', formData)
            .then(response => {
              this.profileData = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$refs.updateDetailsForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updatePassword() {
      this.isLoading = true
      this.$refs.updatePasswordForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('old_password', this.old_password)
          formData.append('new_password', this.new_password)
          formData.append('confirm_new_password', this.confirm_new_password)
          this.$http.post('membership/update-password', formData)
            .then(response => {
              this.profileData = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              this.old_password = ''
              this.new_password = ''
              this.confirm_new_password = ''
            })
            .catch(error => {
              console.log(error.response.data)
              this.$refs.updatePasswordForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updateNextOfKin() {
      this.isLoading = true
      this.$refs.updateNextOfKinForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('name', this.next_of_kin_name)
          formData.append('phone_number', this.next_of_kin_phone_number)
          formData.append('relationship', this.next_of_kin_relationship)
          formData.append('identification_doc', this.identification_document)
          this.$http.post('membership/update-next-of-kin', formData)
            .then(response => {
              this.profileData = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              console.log(error.response.data)
              this.$refs.updatePasswordForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updatePaymentInformation() {
      this.isLoading = true
      this.$refs.updatePaymentInformationForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('bank_name', this.bank_name)
          formData.append('bank_branch', this.bank_branch)
          formData.append('bank_account_name', this.bank_account_name)
          formData.append('bank_account_number', this.bank_account_number)
          formData.append('mobile_money_provider', this.mobile_money_provider)
          formData.append('mobile_money_registered_name', this.mobile_money_registered_name)
          formData.append('mobile_money_phone_number', this.mobile_money_phone_number)
          this.$http.post('membership/update-payment-information', formData)
            .then(response => {
              this.profileData = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              this.old_password = ''
              this.new_password = ''
              this.confirm_new_password = ''
            })
            .catch(error => {
              console.log(error.response.data)
              this.$refs.updatePasswordForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updatePartnersInformation() {
      this.isLoading = true
      this.$refs.updatePartnersInformationForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index <= this.partners_counter; index++) {
            this.partners[index] = {
              first_name: this.partners_first_name[index],
              last_name: this.partners_last_name[index],
              id_number: this.partners_id_number[index],
              email: this.partners_email[index],
              phone_number: this.partners_phone_number[index],
              shares: this.partners_shares[index],
            }
          }

          // const formData = new FormData()
          // formData.append('partners', this.partners.pop())
          this.$http.post('membership/update-partners', { partners: this.partners.reverse() })
            .then(response => {
              this.profileData = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              this.old_password = ''
              this.new_password = ''
              this.confirm_new_password = ''
            })
            .catch(error => {
              console.log(error.response.data)
              this.$refs.updatePasswordForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    nth(n) {
      // eslint-disable-next-line no-mixed-operators
      return ['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || 'th'
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
.m_title {
  font-weight: bolder;
}
</style>
